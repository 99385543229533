import axios from "axios";

const API = "calificaciones";

const Calificaciones = {
    get_datos(params){
        return axios(`${API}/get-datos`, { params })
    },
    get_calificaciones(id_pedido, params){
        return axios(`${API}/${id_pedido}/get-calificaciones`, { params });
    },
    get_resumen_calificacion(id_tienda){
        return axios(`resumen-calificacion/${id_tienda}/2`);
    },
    get_resumen_calificacion_cedis(idCedis){
        return axios(`resumen-calificacion-cedis/${idCedis}`);
    },
    get_items_calificar(){
        return axios(`${API}/items`);
    },
    calificar_pedido_admin(id_pedido,model){
        return axios.put(`${API}/pedido-admin/${id_pedido}/calificar`,model);
    },
};

export default Calificaciones;
