<template>
    <section>
        <modal ref="modalCalificacionesGenerales" no-aceptar :titulo=" tienda.calificacion_cantidad + ' Calificaciones'" icon="review">
            <div v-for="(calificacion, c) in calificaciones" :key="c" class="row mx-0 mt-3 justify-center">
                <div class="col-4">
                    <p class="text-general f-17 f-600 text-center">
                        {{ calificacion.nombre }}
                    </p>
                </div>
                <div class="col-4">
                    <p class="text-general f-17 f-600 text-center">
                        {{ calificacion.promedio }}
                        <i class="icon-star" style="color:#FF9D32;" />
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Calificaciones from "../../../services/calificaciones";
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            calificaciones: [],
            tienda: {
                calificacion: 0,
                calificacion_cantidad: 0
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = 0
            }

            return id_tienda
        },
        caso(){
            if (this.$route.name == 'admin.tiendas.produccion.calificaciones'){
                return 1
            } else if (this.$route.name == 'admin.clientes-estadisticas.calificaciones'){
                return 3
            } else {
                return 2
            }
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCalificacionesGenerales.toggle();
            if(this.caso == 3){
                this.get_resumen_calificacion_cedis()

            }else{

                this.get_resumen_calificacion()
            }
        },
        async get_resumen_calificacion(){
            try {
                let params = {
                    caso: this.caso,
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Calificaciones.get_resumen_calificacion(this.id_tienda)
                this.calificaciones = data.data.calificacion_tipo
                this.tienda.calificacion = data.data.calificacion
                this.tienda.calificacion_cantidad = data.data.calificacion_cantidad
            } catch (error){
                this.error_catch(error)
            }
        },
        async get_resumen_calificacion_cedis(){
            try {
                const { data } = await Calificaciones.get_resumen_calificacion_cedis(this.id_cedis)
                this.calificaciones = data.data.calificacion_tipo
                this.tienda.calificacion = data.data.calificacion
                this.tienda.calificacion_cantidad = data.data.calificacion_cantidad
            } catch (error){
                this.error_catch(error)
            }
        },
        async get_resumen_calificacion2(id_tienda){ // La de joaquin no me sirvio att: yesid
            try {

                const { data } = await Calificaciones.get_resumen_calificacion(id_tienda)
                this.calificaciones = data.data.calificacion_tipo
                this.tienda.calificacion = data.data.calificacion
                this.tienda.calificacion_cantidad = data.data.calificacion_cantidad
                this.$refs.modalCalificacionesGenerales.toggle();
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
